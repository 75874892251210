import { Flex, Box, Input, Image } from "@chakra-ui/react";

const HeroSection = () => {
  return (
    <section className="body-font text-gray-600">
      <div className="container mx-auto flex flex-col items-center px-5 py-24 md:flex-row">
        <div className="mb-16 flex flex-col items-center text-center md:mb-0 md:w-1/2 md:items-start md:pr-16 md:text-left lg:flex-grow lg:pr-24">
          <h1 className="title-font flex flex-col mb-4 text-3xl font-medium text-gray-900 sm:text-4xl">
          Welcome to Gift Shops Finder
          <span className="text-lg text-gray-500 mt-2">Your Ultimate Guide to Gift Shops Across the USA</span>
          </h1>
          <p className="mb-8 leading-relaxed">
          Finding the perfect gift shop in your city just got easier! At Gift Shops Finder, we bring you a comprehensive listing of gift shops in cities across the United States. Whether you&apos;re searching for a unique local boutique or a popular chain, our platform helps you discover the best gift shops near you, all in one place.
          </p>
          <div className="flex w-full items-end justify-center md:justify-start">
            <div className="relative mr-4 w-2/4 md:w-full lg:w-full xl:w-1/2">
              <input
                type="text"
                id="hero-field"
                name="hero-field"
                placeholder="Search your local area"
                className="w-full rounded border border-gray-300 bg-gray-100 bg-opacity-50 px-3 py-1 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:bg-transparent focus:ring-2 focus:ring-indigo-200"
              />
            </div>
            <button className="inline-flex rounded border-0 bg-indigo-500 px-6 py-2 text-lg text-white hover:bg-indigo-600 focus:outline-none">
              Search
            </button>
          </div>
          <p className="mb-8 mt-2 w-full text-sm text-gray-500">
           Best Gift Shops Finder stores near me.
          </p>
        </div>
        <div className="w-5/6 md:w-1/2 lg:w-full lg:max-w-lg">
          <img
            className="rounded object-cover object-center"
            alt="hero"
            src="/assets/imgs/no-image.jpg"
          />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;

import { Box, Grid, Image, Text } from '@chakra-ui/react';

const ServicesSection = ({services}) => {
  // const services = [
  //   { title: 'Cell Repair', image: '/images/cell-repair.jpg' },
  //   { title: 'Cell Phone Screen Repair', image: '/images/screen-repair.jpg' },
  //   { title: 'Cell Phone Battery Repair', image: '/images/battery-repair.jpg' },
  //   { title: 'Buy a New Cell Phone', image: '/images/new-phone.jpg' }
  // ];

  return (
    <Grid templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }} gap={6} p={5}>
      {services.map(service => (
        <Box key={service.title} maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden">
          <Image src={service.image} alt={`${service.title} image`} />
          <Box p={5}>
            <Text fontSize="xl" fontWeight="bold">{service.title}</Text>
          </Box>
        </Box>
      ))}
    </Grid>
  );
};

export default ServicesSection;

import React from "react";

const FeaturedCard = ({title, icon, description}) => {
  return (
   
        <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 p-6">
          <div className="flex items-center space-x-3 mb-4">
            <div className="text-blue-500">{icon}</div>
            <h3 className="text-xl font-semibold text-gray-800">
              {title}
            </h3>
          </div>
          <p className="text-gray-600">{description}</p>
        </div>

  );
};

export default FeaturedCard;

import React from "react";
import Image from "next/image";
import Link from 'next/link'

const SectionImage = ({sectionImg, sectionBody}) => {
  return (
    <section className="body-font relative">
    <div className="absolute inset-0 w-full h-full bg-black opacity-80"></div>
    <Image
      src={sectionImg}
      width='0'
      height='0'
      className="absolute inset-0 object-cover opacity-10 w-full h-full"
      alt="Volt and Secure"
    />
    <div className="w-5/6 md:w-3/5 px-2 md:px-5 py-12 md:py-24 mx-auto flex flex-wrap relative">
     {sectionBody}
    </div>
  </section>

  );
};

export default SectionImage;

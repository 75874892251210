const updateDataFromRapidAPI = async () => {
    try {
      const response = await fetch("/api/listings", {
        method: "POST",
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error updating data from RapidAPI:", error);
      return null;
    }
  };

  export default updateDataFromRapidAPI;
import React from "react";
import Link from "next/link";
import Image from "next/image";

const TwoColumn = ({ firstColumn, secondColumn,bgImage }) => {
  return (
    <>
      <section>
        <div className="bg-sky-700  relative">
          <div className="absolute inset-0 w-full h-full z-0 bg-black opacity-80"></div>
          <Image
            src={bgImage}
            width="0"
            height="0"
            className="absolute inset-0 object-cover opacity-10 w-full h-full"
            alt="Volt and Secure"
          />
          <div className="px-3 py-16 mx-auto sm:max-w-xl  md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="flex flex-col items-center justify-between xl:flex-row">
              <div className="w-full max-w-xl  xl:w-1/2 max-sm:mb-6 z-10">
                {firstColumn}
              </div>
              <div className="w-full max-w-xl mb-12 xl:mb-0 xl:pr-16 xl:w-7/12 z-10">
                {secondColumn}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TwoColumn;
